<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="抖音账号/昵称"/>
                </el-form-item>
                <el-form-item>
                    <tb-select ref="level" :options="merchantOptions"
                               @change="val => this.options.params.level_id = val"/>

                </el-form-item>
                <el-form-item>
                    <el-select clearable v-model="options.params.status" placeholder="用户类型筛选">
                        <el-option :key="1" :value="1" label="普通用户"></el-option>
                        <el-option :key="2" :value="2" label="加盟达人"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
            <template slot="actions" slot-scope="scope">
                <el-button v-if="$perm('league_Member_edit')" type="text"
                           @click="upgrade(scope.row)" icon="el-icon-check"
                           class="color-primary"> 抖号调整
                </el-button>
                <el-button type="text" @click="detail(scope.row)" icon="el-icon-view"
                           class="color-success"> 抖音账号
                </el-button>
            </template>
        </crud>
        <!-- 抖音账号信息 -->
        <tb-dialog ref="dialog" :confirm="false" :title="dialogTitle">
            <el-form label-width="100px">
                <el-form-item label="抖音账号">
                    <span>{{ info.account }}</span>
                </el-form-item>
                <el-form-item label="抖音昵称">
                    <span>{{ info.nickname }}</span>
                </el-form-item>
                <el-form-item label="抖音头像">
                    <el-image class="list-user-avatar" :preview-src-list="[info.avatar]" :src="info.avatar"></el-image>
                </el-form-item>
                <el-form-item label="账号性别">
                    <span>{{ info.gender === 1 ? '男' : info.gender === 2 ? '女' : '未知' }}</span>
                </el-form-item>
                <el-form-item label="所在地区">
                    <span>{{ info.region }}</span>
                </el-form-item>
                <el-form-item label="粉丝数量">
                    <span>{{ info.fans }}</span>
                </el-form-item>
                <el-form-item label="等级截图">
                    <el-image class="list-user-avatar" :preview-src-list="[info.level]" :src="info.level"></el-image>
                </el-form-item>
            </el-form>
        </tb-dialog>
        <!-- 账户升级 -->
        <tb-dialog ref="upgradeDialog" :confirm="true" title="升级为达人" @onOpened="onFormOpened" @onConfirm="doUpgrade">
            <el-form ref="upgradeForm" label-width="100px" :model="upgradeForm" :rules="upgradeFormRules">
                <el-form-item label="抖音账号" prop="account">
                    <el-input placeholder="请输入抖音账号" v-model="upgradeForm.account"></el-input>
                </el-form-item>
                <el-form-item label="抖音昵称" prop="nickname">
                    <el-input placeholder="请输入抖音账号" v-model="upgradeForm.nickname"></el-input>
                </el-form-item>
                <el-form-item label="抖音头像" prop="avatar">
                    <tb-image action="resource.upload.image"
                              :images="upgradeForm.avatar ? [upgradeForm.avatar] : []" :count="1"
                              @remove="() => this.upgradeForm.avatar = null"
                              @uploaded=" data => this.upgradeForm.avatar = data.src"/>
                </el-form-item>
                <el-form-item label="账号性别" prop="gender">
                    <el-radio-group v-model="upgradeForm.gender">
                        <el-radio :label="1">帅哥</el-radio>
                        <el-radio :label="2">美女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="所在站点" prop="site_id">
                    <tb-select ref="site" :options="siteOptions"
                               @change="val => this.upgradeForm.site_id = val"/>
                </el-form-item>
                <el-form-item label="粉丝数量" prop="fans">
                    <el-input placeholder="请输入粉丝数量" type="number" v-model="upgradeForm.fans"></el-input>
                </el-form-item>
                <el-form-item label="带货等级" prop="level_id">
                    <tb-select ref="formLevel" :options="levelOptions"
                               @change="val => this.upgradeForm.level_id = val"/>
                </el-form-item>
            </el-form>
        </tb-dialog>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: false,
                editBtn: false,
                delBtn: true,
                pager: true,
                actionWidth: 260,
                addUrl: 'league.Member.add',
                addPerm: 'league_Member_add',
                editUrl: 'league.Member.edit',
                editPerm: 'league_Member_edit',
                delUrl: 'league.Member.delete',
                delPerm: 'league_Member_delete',
                listUrl: 'league.Member.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null,
                    status: null,
                    level_id: null
                },
                columns: [
                    {prop: "nickname", label: "昵称",},
                    {
                        prop: "avatar", label: "头像", type: 'image',
                        width: 72,
                        html: ({row}) => {
                            return row.avatar ? `<img class="list-user-avatar" src="${row.avatar}" alt="加载失败"/>` : null
                        }
                    },
                    {
                        prop: "gender", label: "性别", type: "radio",
                        required: true,
                        value: 1,
                        dicData: [{label: '男', value: 1}, {label: '女', value: 2}],
                        formatter: row => {
                            return row.gender === 1 ? '男' : '女'
                        }
                    },
                    {
                        prop: "status", label: "类型", type: "radio",
                        required: true,
                        value: 1,
                        dicData: [{label: '普通用户', value: 1}, {label: '加盟达人', value: 2}],
                        formatter: row => {
                            return row.status === 2 ? '加盟达人' : '普通用户'
                        }
                    },
                    {prop: "country", label: "国家", required: true},
                    {prop: "province", label: "省份", required: true},
                    {prop: "city", label: "城市", required: true},
                    {
                        prop: "parent_id", label: "邀请人", formatter: row => {
                            return row.superior ? row.superior.nickname : '平台'
                        }
                    },
                    {
                        prop: "level_id", label: "会员等级", formatter: row => {
                            return row.level ? row.level.name : '无'
                        }
                    },
                    {prop: "balance", label: "账户余额",},
                    {prop: "invited", label: "已邀请人数", type: "number", required: true},
                    {prop: "create_time", label: "注册时间", width: 140},
                ]
            },
            merchantOptions: {
                listUrl: 'league.MemberLevel.list',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '会员等级筛选'
            },
            siteOptions: {
                listUrl: 'league.Site.list',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '请选择所在站点'
            },
            levelOptions: {
                listUrl: 'league.MemberLevel.list',
                filter: (item, value) => {
                    return !value || item.name.includes(value)
                },
                label: "item.name",
                placeholder: '请选择带货等级'
            },
            dialogTitle: '抖音账号信息',
            info: {
                nickname: null,
                avatar: null,
                gender: null,
                region: null,
                account: null,
                fans: null,
                level: null
            },
            upgradeForm: {
                member_id: null,
                avatar: null,
                nickname: null,
                gender: 1,
                account: null,
                fans: null,
                level_id: null,
                site_id: null,
            },
            upgradeFormRules: {
                avatar: [
                    {required: true, message: '请上传抖音头像~', trigger: 'blur'}
                ],
                nickname: [
                    {required: true, message: '请填写抖音昵称~', trigger: 'blur'}
                ],
                gender: [
                    {required: true, message: '请选择抖音账户性别~', trigger: 'blur'}
                ],
                account: [
                    {required: true, message: '请填写抖音账号~', trigger: 'blur'}
                ],
                fans: [
                    {required: true, message: '请填写粉丝数量~', trigger: 'blur'}
                ],
                level_id: [
                    {required: true, message: '请选择带货等级~', trigger: 'blur'}
                ],
                site_id: [
                    {required: true, message: '请选择所在站点~', trigger: 'blur'}
                ]
            }
        }
    },
    activated: function () {
        this.$refs.level.getLists()
    },
    methods: {
        detail: function (row) {
            if (!row.profile) return this.$message.warning("未加入达人或未审核资料~")
            this.dialogTitle = `【${row.nickname}】抖音账号信息`
            this.info = row.profile
            this.$refs.dialog.show()
        },
        upgrade: function (row) {
            // this.$helper._post(this, 'league.Member.upgrade', {id: row.id}, resp => {
            //     this.$message.success(resp.message)
            //     this.$refs.crud.getLists()
            // })
            this.upgradeForm = Object.assign({}, row.profile ? row.profile : this.upgradeForm)
            this.upgradeForm.member_id = row.id

            this.$refs.upgradeDialog.show()
        },
        doUpgrade: function () {
            this.$refs.upgradeForm.validate(valid => {
                if (!valid) {
                    return
                }
                this.$helper._post(this, 'league.Member.upgrade', this.upgradeForm, resp => {
                    this.$refs.upgradeDialog.hide()
                    this.$message.success(resp.message)
                    this.$refs.crud.getLists()
                })
            })
        },
        onFormOpened: function () {
            this.$refs.site.getLists()
            this.$refs.site.setValue(this.upgradeForm.site_id)
            this.$refs.formLevel.getLists()
            this.$refs.formLevel.setValue(this.upgradeForm.level_id)
        }
    }
}
</script>

<style scoped>

</style>
